import { useState } from 'react';
import { useToast } from '@context/ToastContext';
import Buttom from '@components/buttons';
import './style.css';
import { saveWhatLooking } from '@hooks/useAuth';
import TextArea from '@components/forms/textArea';
import { useSelector } from 'react-redux';
import { AppStateType } from '@redux/store';
import { userData } from '@redux/slices/initLoad/types';
import { store } from '@redux/store';

const LoginForm = () => {
  const { showToast } = useToast();

  const { user } = useSelector((state: AppStateType) => state.initLoad);

  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);

  const save = () => {
    const data = {
      text,
    };

    saveWhatLooking(data)
      .then(() => {
        showToast('Success.', 'success');
        store.dispatch(userData({ ...user, what_looking: text }));
      })
      .catch(() => {
        showToast('Ha ocurrido un error.', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="login-container">
      {loading && <div className="loadingAuth">laoding</div>}

      <br />
      <br />
      <h2>¿Qué servicios o productos estas buscando?</h2>
      <TextArea
        value={text}
        placeholder="Cuentanos un poco sobre que buzcas en la cervezatón"
        onChange={setText}
      />

      <br />
      <Buttom size="lg" label="Guardar" onClick={save} />
    </div>
  );
};

export default LoginForm;
