import { useState } from 'react';
import { useToast } from '@context/ToastContext';
import { InputText } from '@components/forms';
import Buttom from '@components/buttons';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { authLogin } from '@hooks/useAuth';

const LoginForm = () => {
  const { showToast } = useToast();

  const navigate = useNavigate();

  const [document, setDocument] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const onAuth = () => {
    const data = {
      document,
      password,
    };

    authLogin(data)
      .then(() => {
        showToast('¡Felicidades! Has iniciado sesión con éxito.', 'success');
        navigate('/');
      })
      .catch(() => {
        showToast('Cédula o contraseña incorrecta, intenta de nuevo.', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="login-container">
      {loading && <div className="loadingAuth">laoding</div>}

      <br />
      <br />
      <h2>Ingresa tu cédula</h2>
      <InputText value={document} onChange={setDocument} />

      <br />

      <h2>Ingresa tu clave</h2>
      <InputText value={password} onChange={setPassword} />

      <br />

      <br />
      <p>
        Con tu cédula, podremos verificar si formas parte de la lista exclusiva
        de miembros de la Cervezatón.
      </p>

      <br />
      <br />
      <Buttom size="lg" label="Ingresar" onClick={onAuth} />
    </div>
  );
};

export default LoginForm;
