export const interest = [
  'Marketing Digital',
  'SEO',
  'SEM',
  'Email Marketing',
  'Marketing en Redes Sociales',
  'Marketing de Contenidos',
  'Inbound Marketing',
  'Publicidad en Línea',
  'Google Ads',
  'Facebook Ads',
  'Automatización del Marketing',
  'CRM',
  'Flujos de Trabajo Automatizados',
  'Branding y Gestión de Marca',
  'Marketing de Influencers',
  'Marketing de Afiliación',
  'Estrategia de Producto',
  'Análisis de Competencia',
  'Análisis y Métricas de Marketing',
  'Growth Hacking',
  'Fidelización de Clientes',
  'Experiencia de Usuario (UX)',
  'Creación de Embudos de Ventas',
  'Segmentación de Audiencias',
  'Comunicación Corporativa',
  'Estrategia de Precios',
  'Optimización de Conversión',
  'Pruebas A/B',
  'Retargeting y Remarketing',
  'Neuromarketing',
  'Big Data y Marketing',
  'Publicidad Nativa',
  'Ventas B2B',
  'Ventas B2C',
  'Desarrollo de Negocios',
  'Gestión de Proyectos',
  'Finanzas Corporativas',
  'Consultoría Empresarial',
  'Emprendimiento',
  'Innovación Empresarial',
  'Estrategia de Expansión Internacional',
  'Capital de Riesgo',
  'Fondos de Inversión',
  'Private Equity',
  'Crowdfunding',
  'Inversiones Inmobiliarias',
  'Inversiones en Startups',
  'Análisis Financiero',
  'Gestión de Activos',
  'Inversiones de Impacto',
  'Criptomonedas',
  'Trading y Mercados Financieros',
  'Inversión en Bonos',
  'Planificación Financiera Personal',
  'Hedge Funds',
];
