import { createAction } from '@reduxjs/toolkit';

export type UserData = {
  id: number;
  name: string;
  email: string;
  instagram: string;
  phone: string;
  document: string;
  location: string | null;
  company: string;
  interest: string | null;
  rol: string;
  what_offer: string;
  what_looking: string;
  type_company_work: string;
  pay: number;
  pay_vip: number;
  email_verified_at: string | null;
  image: string | null;
  created_at: string;
  updated_at: string;

  category?: string; // Asegúrate de que esta línea esté presente si necesitas usar category
};
export interface IAuthState {
  isFirstLoad: boolean;
  isSingin: boolean;
  user: UserData;
  showlogout: boolean;
  scroll: any;
}

export const initialState: IAuthState = {
  isFirstLoad: true,
  isSingin: false,
  showlogout: true,
  scroll: 0,
  user: {
    id: 0,
    name: '',
    image: '',
    email: '',
    instagram: '',
    phone: '',
    document: '',
    location: null,
    company: '',
    interest: null,
    rol: '',
    what_offer: '',
    what_looking: '',
    type_company_work: '',
    pay: 0,
    pay_vip: 0,
    email_verified_at: null,
    created_at: '',
    updated_at: '',
  },
};

export const isFirstLoad = createAction<boolean>('root/isFirstLoad');
export const isSingin = createAction<boolean>('root/isSingin');
export const userData = createAction<UserData>('root/userData');
export const setShowLogout = createAction<boolean>('root/setShowLogout');
export const setScroll = createAction<any>('root/setScroll');
