import './style.css';
import Layout from '@components/layout';
import { AppStateType, store } from '@redux/store';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Buttom from '@components/buttons';
import { updatePhotoProfile } from '@hooks/useProfile';
import { userData } from '@redux/slices/initLoad/types';
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {
  const { user } = useSelector((state: AppStateType) => state.initLoad);
  const navigate = useNavigate();

  const [photoProfile, setPhotoProfile] = useState<any>(user.image);
  const [isChangeProfile, setIsChangeProfile] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const fileInputRef = useRef(null);

  const handleImageClick = () => {
    //@ts-ignore
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoProfile(reader.result);
        setIsChangeProfile(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const savePhotoProfile = async () => {
    if (isChangeProfile) {
      try {
        setLoading(true);
        const result = await updatePhotoProfile(photoProfile);
        store.dispatch(userData({ ...user, image: result.image_url }));
        setIsChangeProfile(false);
        setLoading(false);
      } catch (error) {
        alert(
          'La imagen no se pudo subir debido a un problema de conexión. Por favor, intenta nuevamente.',
        );
        window.location.reload();
      }
    }
  };

  return (
    <Layout showBottomNav={false}>
      <div id="content-profile">
        <h2>Crea una excelente primera impresión</h2>
        <p>
          Añade una foto de perfil profesional <br />
          para destacar en la red.
        </p>
        <div className="photoContainer">
          <img
            src={
              photoProfile ??
              'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541'
            }
            alt=""
            id="photo_profile"
            style={{ cursor: 'pointer' }}
            onClick={handleImageClick}
          />
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept="image/*"
            onChange={handleFileChange}
          />

          <div
            className={`boton-naranja ${!isChangeProfile ? 'disabled' : ''}`}
            onClick={savePhotoProfile}
          >
            <p>{loading ? 'Subiendo foto...' : 'Guardar fotografía'}</p>
          </div>

          <br />
          <br />
        </div>
      </div>
    </Layout>
  );
};

export default LoginForm;
