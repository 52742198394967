import './style.css';

import { AppStateType, store } from '@redux/store';

import { useSelector } from 'react-redux';
import Buttom from '@components/buttons';
import TextArea from '@components/forms/textArea';
import { useState } from 'react';
import { updatePreferences } from '@hooks/useProfile';
import { userData } from '@redux/slices/initLoad/types';

const Index = ({ setSection }: { setSection: any }) => {
  const { user } = useSelector((state: AppStateType) => state.initLoad);

  const [whatOffer, setWhatOffer] = useState<string>(user.what_offer);
  const [whatLooking, setWhatLooking] = useState<string>(user.what_looking);

  const saveData = async () => {
    try {
      const data = {
        what_offer: whatOffer,
        what_looking: whatLooking,
      };
      await updatePreferences(data);
      store.dispatch(
        userData({ ...user, what_offer: whatOffer, what_looking: whatLooking }),
      );
      setSection();
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div id="settings-preferences">
      <h2>¿Qué servicios o productos ofreces?</h2>
      <TextArea
        value={whatOffer}
        placeholder="Cuentanos que ofreces"
        onChange={setWhatOffer}
      />

      <br />

      <h2>¿Qué servicios o productos estas buscando?</h2>
      <TextArea
        value={whatLooking}
        onChange={setWhatLooking}
        placeholder="Cuentanos que buscas"
      />

      <br />

      <div>
        <Buttom size="lg" label="Guardar" onClick={saveData} />
        <div onClick={setSection} className="option-logout">
          Cancelar
        </div>
      </div>
    </div>
  );
};

export default Index;
