import './style.css';
import Layout from '@components/layout';
import { useLocation } from 'react-router-dom';

const LoginForm = () => {
  const { profile } = useLocation().state;

  return (
    <Layout showBottomNav={true}>
      <div className="direcotorie-profile-container">
        <div className="photo-profile">
          <img
            src={
              profile.image ??
              'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
            }
            onError={(e) => {
              (e.target as HTMLImageElement).src =
                'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';
            }}
            alt={`${profile.name}'s profile`}
          />
          <div className="entrepreneur-caption-profile">
            <p className="title">{profile.name}</p>
            <p className="rol">{profile.rol}</p>
          </div>
        </div>

        <div>
          <h2>Resumen</h2>

          <div className="item-summary-profile">
            <i className="fas fa-map-marker-alt"></i>
            <p>{profile.location}</p>
          </div>

          <div className="item-summary-profile">
            <i className="fas fa-building"></i>
            <p>{profile.company}</p>
          </div>
        </div>

        <div>
          <h2>Intereses</h2>
        </div>
        <div className="direcotorie-profile-interest">
          <div className="interest-item interest-what-offer">
            <h2>¿Qué ofrezco?</h2>
            <p className="limited-text">{profile.what_offer}</p>
          </div>
          <div className="interest-item interest-what-looking">
            <h2>¿Qué busco?</h2>
            <p className="limited-text">{profile.what_looking}</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LoginForm;
