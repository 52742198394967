import './style.css';
import Buttom from '@components/buttons';
import InputText from '@components/forms/inputText';
import { useState } from 'react';
import { updatePassword } from '@hooks/useProfile';

const Index = ({ setSection }: { setSection: any }) => {
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');

  const saveData = async () => {
    try {
      const data = {
        currentpassword: currentPassword,
        newpassword: newPassword,
      };
      await updatePassword(data);

      setSection();
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div id="settings-password">
      <h2 style={{ textAlign: 'left' }}>Contraseña actual</h2>
      <InputText
        type="password"
        value={currentPassword}
        onChange={setCurrentPassword}
      />

      <br />

      <h2 style={{ textAlign: 'left' }}>Nueva Contraseña</h2>
      <InputText
        type="password"
        value={newPassword}
        onChange={setNewPassword}
      />

      <br />

      <div>
        <Buttom size="lg" label="Guardar" onClick={saveData} />
        <div onClick={setSection} className="option-logout">
          Cancelar
        </div>
      </div>
    </div>
  );
};

export default Index;
