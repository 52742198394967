import React from 'react';
import './style.css';
import { Link, useLocation } from 'react-router-dom';

const BottomNavigation: React.FC = () => {
  const location = useLocation();
  return (
    <div className="bottom-navigation-content">
      <div className="bottom-navigation">
        <Link to="/">
          <div
            className={`nav-item ${
              location.pathname === '/' ? 'activaitemnav' : ''
            }`}
          >
            <i className="fas fa-book"></i>
          </div>
          <p>Directorio</p>
        </Link>
        <Link to="/explore">
          <div
            className={`nav-item ${
              location.pathname === '/explore' ? 'activaitemnav' : ''
            }`}
          >
            <i className="fas fa-search"></i>
          </div>
          <p>Conocer</p>
        </Link>
        <Link to="/speed/dating">
          <div
            className={`nav-item ${
              location.pathname === '/speed/dating' ? 'activaitemnav' : ''
            }`}
          >
            <i className="fas fa-calendar"></i>
          </div>
          <p>SpeedDating</p>
        </Link>
        <Link to="/matches">
          <div
            className={`nav-item ${
              location.pathname === '/matches' ? 'activaitemnav' : ''
            }`}
          >
            <i className="fas fa-comment"></i>
          </div>
          <p>Chats</p>
        </Link>
        <Link to="/profile">
          <div
            className={`nav-item ${
              location.pathname === '/profile' ? 'activaitemnav' : ''
            }`}
          >
            <i className="fas fa-user"></i>
          </div>
          <p>Perfil</p>
        </Link>
      </div>
    </div>
  );
};

export default BottomNavigation;
