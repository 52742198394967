import requests from 'helpers/requests';

export const updatePhotoProfile = async (photo: string) => {
  try {
    const response = await requests.post('udpate/photo/profile', { photo });

    return response.data;
  } catch (error: any) {
    console.log({ error });
    const errorMessage =
      error?.response?.data?.message || 'An unexpected error occurred';
    throw new Error(errorMessage);
  }
};

export const updatePreferences = async (data: any) => {
  try {
    const response = await requests.post('udpate/preferences', data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    const errorMessage =
      error?.response?.data?.message || 'An unexpected error occurred';
    throw new Error(errorMessage);
  }
};

export const updatePassword = async (data: any) => {
  try {
    const response = await requests.post('udpate/password', data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    const errorMessage =
      error?.response?.data?.message || 'An unexpected error occurred';
    throw new Error(errorMessage);
  }
};

export const updatePersonalData = async (data: any) => {
  try {
    const response = await requests.post('udpate/personal/data', data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    const errorMessage =
      error?.response?.data?.message || 'An unexpected error occurred';
    throw new Error(errorMessage);
  }
};
