import './style.css';
import Buttom from '@components/buttons';
import InputText from '@components/forms/inputText';
import { useState } from 'react';
import { updatePersonalData } from '@hooks/useProfile';
import { useSelector } from 'react-redux';
import { AppStateType, store } from '@redux/store';
import { userData } from '@redux/slices/initLoad/types';

const Index = ({ setSection }: { setSection: any }) => {
  const { user } = useSelector((state: AppStateType) => state.initLoad);

  const [name, setName] = useState<string>(user.name);
  const [rol, setRol] = useState<string>(user.rol);
  const [location, setLocation] = useState<string>(user.location ?? '');
  const [company, setCompany] = useState<string>(user.company);

  const saveData = async () => {
    try {
      const data = {
        name,
        rol,
        location,
        company,
      };
      await updatePersonalData(data);

      store.dispatch(userData({ ...user, name, rol, location, company }));

      setSection();
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div id="settings-personal-data">
      <h2 style={{ textAlign: 'left' }}>Nombres</h2>
      <InputText value={name} onChange={setName} />

      <br />

      <h2 style={{ textAlign: 'left' }}>Rol</h2>
      <InputText value={rol} onChange={setRol} />
      <br />
      <h2 style={{ textAlign: 'left' }}>Ubicación</h2>
      <InputText value={location} onChange={setLocation} />

      <br />
      <h2 style={{ textAlign: 'left' }}>Empresa</h2>
      <InputText value={company} onChange={setCompany} />

      <br />

      <div>
        <Buttom size="lg" label="Guardar" onClick={saveData} />
        <div onClick={setSection} className="option-logout">
          Cancelar
        </div>
      </div>
    </div>
  );
};

export default Index;
