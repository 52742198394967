import { useState } from 'react';
import { useToast } from '@context/ToastContext';
import Buttom from '@components/buttons';
import { saveIntereset } from '@hooks/useAuth';
import { useSelector } from 'react-redux';
import { AppStateType } from '@redux/store';
import { userData } from '@redux/slices/initLoad/types';
import { store } from '@redux/store';
import { interest } from '@screens/home/interest';
import './style.css';

const Index = () => {
  const { showToast } = useToast();

  const { user } = useSelector((state: AppStateType) => state.initLoad);
  const [selectedInterests, setSelectedInterests] = useState<string[]>([]);

  const handleSelectInterest = (interest: string) => {
    if (selectedInterests.includes(interest)) {
      setSelectedInterests(selectedInterests.filter((i) => i !== interest));
    } else {
      setSelectedInterests([...selectedInterests, interest]);
    }
  };

  const [loading, setLoading] = useState(false);

  const save = () => {
    saveIntereset(selectedInterests.join(','))
      .then(() => {
        showToast('Success.', 'success');
        store.dispatch(
          userData({ ...user, interest: selectedInterests.join(',') }),
        );
      })
      .catch(() => {
        showToast('Ha ocurrido un error.', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="interest-container">
      {loading && <div className="loadingAuth">laoding</div>}

      <h2>Selecciona tus intereses</h2>
      <div className="badges-container">
        {interest.map((item, index) => (
          <span
            key={index}
            className={`badge-item ${
              selectedInterests.includes(item) ? 'selected' : ''
            }`}
            onClick={() => handleSelectInterest(item)}
          >
            {item}
          </span>
        ))}
      </div>

      <br />
      <Buttom size="lg" label="Guardar" onClick={save} />
    </div>
  );
};

export default Index;
