import { useState } from 'react';
import { useToast } from '@context/ToastContext';
import { InputText } from '@components/forms';
import Buttom from '@components/buttons';
import './style.css';
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {
  const { showToast } = useToast();

  const navigate = useNavigate();
  return (

      <div className="container">
         <div className="logo"></div>
         <div className="centered-container ">
            <div className="back"></div>
           
            <img src="https://cervezaton.com/wp-content/uploads/2024/10/v451_98.png" className="image-large" />
      
              <div className="image-row">
                <img src="https://cervezaton.com/wp-content/uploads/2024/10/v451_86.png" className="image" />
                <img src="https://cervezaton.com/wp-content/uploads/2024/10/v451_86.png" className="image" />
              </div>

            <div className="text">
               <span>Has conectado con <b>David</b></span>
            </div>
              <span className="boton">Escríbele por WhatsApp</span>
         </div>     
      </div>

  );
};

export default LoginForm;
