import React, { useEffect, useState } from 'react';

const DeviceRestriction = ({ children }: any) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    //@ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android|iphone|ipad|mobile/i.test(userAgent)) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  if (!isMobile) {
    return (
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <h1>Esta aplicación solo está disponible en dispositivos móviles.</h1>
      </div>
    );
  }

  return <>{children}</>;
};

export default DeviceRestriction;
