import { useSlots } from '@hooks/useSlots';
import './style.css';
import Layout from '@components/layout';

const LoginForm = () => {
  const { scheduledAppointments, rejectInvitation, getScheduledAppointments } =
    useSlots();

  const reject = async (id: any) => {
    const data = { slot_id: id };
    const isConfirmed = window.confirm('Esta seguro de realizar esta accion?');
    if (isConfirmed) {
      await rejectInvitation(data);
      window.location.reload();
    }
  };

  return (
    <Layout showBottomNav={true}>
      <div id="speed-dating-shedulle-appointments">
        <h2>SpeedDating</h2>
        <br />
        <div className="content-shedulle-appointments">
          <center>
            <h3>Citas programadas</h3>
          </center>
        </div>

        <div>
          {scheduledAppointments.length > 0 ? (
            scheduledAppointments.map((item: any) => {
              return (
                <div className="invite-card" key={item.id}>
                  <div className="invite-card-content">
                    <img
                      className="invite-card-image"
                      src={item.user.image}
                      alt={item.user.name}
                    />
                    <div className="invite-card-info">
                      <h3 className="invite-card-name">{item.user.name}</h3>
                      <p>Tienes una cita programada</p>
                      <p>
                        <strong>
                          Hora: {item.reservation.start_time} -{' '}
                          {item.reservation.end_time}
                        </strong>
                      </p>
                    </div>
                  </div>
                  <div className="invite-card-actions">
                    <button
                      className="invite-card-reject"
                      onClick={() => reject(item.reservation_id)}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <p>No tienes citas programadas en este momento.</p>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default LoginForm;
