import './style.css';
import Layout from '@components/layout';
import TinderCard from 'react-tinder-card';
import { useSwipe } from '@hooks/useSwipe';
import { useToast } from '@context/ToastContext';
import { createRef, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppStateType } from '@redux/store';

const LoginForm = () => {
  const { data: initialPeople, handleSwipe, refresh } = useSwipe();
  const { user } = useSelector((state: AppStateType) => state.initLoad);
  const { showToast } = useToast();

  const [people, setPeople] = useState<any>(initialPeople);
  const [match, setMatch] = useState<boolean>(false);
  const [matchImage, setMatchImage] = useState<any>(null);
  const [matchName, setMatchName] = useState<any>(null);
  const [matchPhone, setMatchPhone] = useState<any>(null);
  const [swipeCount, setSwipeCount] = useState(0);

  const [currentIndex, setCurrentIndex] = useState(people.length - 1);

  const currentIndexRef = useRef(currentIndex);

  useEffect(() => {
    if (match) {
      setTimeout(() => {
        window.location.reload();
        setMatch(false);
      }, 5000);
    }
  }, [match]);

  useEffect(() => {
    setCurrentIndex(people.length - 1);
  }, [people]);

  const childRefs = useMemo(
    () =>
      Array(people.length)
        .fill(0)
        .map(() => createRef()),
    [people.length],
  );

  const updateCurrentIndex = (val: any) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const canSwipe = currentIndex >= 0;

  useEffect(() => {
    setPeople(initialPeople);
  }, [initialPeople]);

  const onSwipe = (
    direction: any,
    swiped_user_id: number,
    image: string,
    name: string,
    phone: string,
    index: number,
  ) => {
    const data = {
      is_liked: direction === 'right' ? true : false,
      swiped_user_id,
    };
    handleSwipe(data).then((response) => {
      if (response.match) {
        setMatchImage(image);
        setMatchName(name);
        setMatchPhone(phone);
        setMatch(true);
        showToast('Match success.', 'success');
      }
    });

    setSwipeCount((prevCount) => prevCount + 1);

    updateCurrentIndex(index - 1);

    if (swipeCount + 1 === people.length) {
      window.location.reload();
      //refresh();
      setSwipeCount(0);
    }
  };

  const onCardLeftScreen = (name: string) => {
    console.log(name + ' left the screen');
  };

  const swipe = async (dir: any) => {
    if (canSwipe && currentIndex < people.length) {
      //@ts-ignore
      await childRefs[currentIndex].current.swipe(dir);
    }
  };

  return (
    <>
      {match ? (
        <div className="container">
          <div className="logo"></div>
          <div className="centered-container ">
            <img
              src="https://cervezaton.com/wp-content/uploads/2024/10/v451_98.png"
              className="image-large"
            />

            <div className="image-row">
              <img
                src={
                  user.image ??
                  'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541'
                }
                className="image"
              />
              <img
                src={
                  matchImage ??
                  'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541'
                }
                className="image"
              />
            </div>

            <div className="text">
              <span>
                Has conectado con <b>{matchName}</b>
              </span>
            </div>
            <a
              href={`https://wa.me/${matchPhone}`}
              target="_blank"
              className="boton"
            >
              Escríbele por WhatsApp
            </a>
          </div>
        </div>
      ) : (
        <Layout showBottomNav={true}>
          <div className="swipe-container">
            {people.map((person: any, index: any) => (
              <TinderCard
                //@ts-ignore
                ref={childRefs[index]}
                key={index}
                className="swipe"
                onSwipe={(dir) =>
                  onSwipe(
                    dir,
                    person.id,
                    person.image,
                    person.name,
                    person.phone,
                    index,
                  )
                }
                onCardLeftScreen={() => onCardLeftScreen(person.name)}
                preventSwipe={['up', 'down']}
              >
                <div
                  className="card-people"
                  style={{
                    backgroundImage: `url(${
                      person.image ??
                      'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
                    })`,
                  }}
                >
                  <div className="overlay"></div>
                  <div className="card-people-caption">
                    <h4>{person.name}</h4>

                    {(person.rol || person.company) && (
                      <div className="item-summary-profile">
                        <i className="fas fa-building"></i>
                        <p>
                          {person.rol}{' '}
                          {person.company ? `en ${person.company}` : ''}
                        </p>
                      </div>
                    )}

                    <div className="item-summary-profile">
                      <p className="limited-text-ther">
                        <b>Busco:</b> {person.what_looking}
                      </p>
                    </div>
                    <div className="item-summary-profile">
                      <p className="limited-text-ther">
                        <b>Ofrezo:</b> {person.what_offer}
                      </p>
                    </div>
                  </div>
                </div>
              </TinderCard>
            ))}
            <div className="maching-people-buttons">
              <div
                className="maching-people-button close"
                onClick={() => swipe('left')}
              >
                <i className="fas fa-times"></i>
                <span>Descartar</span>
              </div>
              <div
                className="maching-people-button"
                onClick={() => swipe('right')}
              >
                <i className="fas fa-check"></i>
                <span>Conectar</span>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default LoginForm;
