import { useEffect } from 'react';

import { isFirstLoad, isSingin, userData } from '@redux/slices/initLoad/types';
import { store } from '@redux/store';
import requests from 'helpers/requests';

export const useInit = () => {
  const getInitData = async () => {
    console.log('get init data');
    store.dispatch(isFirstLoad(true));
    store.dispatch(isSingin(false));

    verifyAuth();
  };

  const verifyAuth = async () => {
    try {
      const response = await requests.post('verifyAuth');

      store.dispatch(isSingin(true));
      store.dispatch(isFirstLoad(false));

      localStorage.setItem('@userData', JSON.stringify(response.data));
      store.dispatch(
        userData({
          id: response.data.id,
          name: response.data.name,
          image: response.data.image,
          email: response.data.email,
          instagram: response.data.instagram,
          phone: response.data.phone,
          document: response.data.document,
          location: response.data.location,
          company: response.data.company,
          interest: response.data.interest,
          rol: response.data.rol,
          what_offer: response.data.what_offer,
          what_looking: response.data.what_looking,
          type_company_work: response.data.type_company_work,
          pay: response.data.pay,
          pay_vip: response.data.pay_vip,
          email_verified_at: response.data.email_verified_at,
          created_at: response.data.created_at,
          updated_at: response.data.updated_at,
        }),
      );
    } catch (error: any) {
      console.log(error, 'error init');

      store.dispatch(isFirstLoad(false));
      store.dispatch(isSingin(false));
      //localStorage.removeItem('@userData');
      // localStorage.removeItem('@tokenAuth');
      console.error('Error verifying authentication:', error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getInitData();
    }, 3000);
  }, []);
};
