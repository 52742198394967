// src/components/ButtonWithRipple.tsx

import React from 'react';
import './style.css';
import { ClipLoader } from 'react-spinners';

type Props = {
  label?: string;
  size?: 'xs' | 'md' | 'lg';
  variant?: 'primary' | 'secondary' | 'line';
  icon?: string;
  loading?: boolean;
  disabled?: boolean;
  showLabel?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const Index: React.FC<Props> = ({
  label = '',
  icon,
  size = 'md',
  variant = 'primary',
  onClick,
  loading = false,
  disabled = false,
  showLabel = true,
}) => {
  const createRipple = (event: React.MouseEvent<HTMLButtonElement>) => {
    const button = event.currentTarget;
    const circle = document.createElement('span');
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
    circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
    circle.classList.add('ripple-effect');

    const ripple = button.getElementsByClassName('ripple-effect')[0];
    if (ripple) {
      ripple.remove();
    }

    button.appendChild(circle);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    createRipple(event);
    if (onClick && !disabled) {
      onClick(event);
    }
  };

  const classVariant = `${
    variant === 'primary' ? 'primary' : 'line' ? 'line' : 'secondary'
  }`;

  const buttonClass = `button text-white ${classVariant} button-${size} ${
    disabled ? 'button-disabled' : ''
  }`;
  return (
    <button className={buttonClass} onClick={handleClick}>
      {loading ? (
        <ClipLoader size={20} color="white" />
      ) : (
        <>
          <div className="label">
            {showLabel && label}

            {icon && (
              <>
                {showLabel && '\u00A0\u00A0'}
                <i className={icon}></i>
              </>
            )}
          </div>
        </>
      )}
    </button>
  );
};

export default Index;
