import './style.css';
import Layout from '@components/layout';

import { AppStateType, store } from '@redux/store';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Buttom from '@components/buttons';
import { updatePhotoProfile } from '@hooks/useProfile';
import { isSingin, userData } from '@redux/slices/initLoad/types';
import SettingsPreferences from './SettingsPreferences';
import SettingsPassword from './SettingsPassword';
import SettingsPersonalData from './SettingsPersonalData';
import SettingsInterest from './SettingsInterest';
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {
  const { user } = useSelector((state: AppStateType) => state.initLoad);
  const navigate = useNavigate();

  const [photoProfile, setPhotoProfile] = useState<any>(user.image);
  const [isChangeProfile, setIsChangeProfile] = useState<boolean>(false);
  const [section, setSection] = useState<
    'profile' | 'preferences' | 'personal_data' | 'password' | 'interest'
  >('profile');

  const fileInputRef = useRef(null);

  const handleImageClick = () => {
    //@ts-ignore
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoProfile(reader.result);
        setIsChangeProfile(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const savePhotoProfile = async () => {
    if (isChangeProfile) {
      try {
        const result = await updatePhotoProfile(photoProfile);
        store.dispatch(userData({ ...user, image: result.image_url }));
        setIsChangeProfile(false);
      } catch (error) {
        alert(error);
      }
    }
  };

  const logout = () => {
    localStorage.removeItem('@tokenAuth');
    localStorage.removeItem('@userData');
    store.dispatch(isSingin(false));
    navigate('/');
  };
  return (
    <Layout showBottomNav={true}>
      <div id="content-profile">
        <h2>Editar perfil</h2>

        <div>
          <img
            src={photoProfile}
            alt=""
            id="photo_profile"
            style={{ cursor: 'pointer' }}
            onClick={handleImageClick}
          />
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept="image/*"
            onChange={handleFileChange}
          />
          <p>Cambiar Foto</p>
          <br />
          <br />

          {isChangeProfile && (
            <div id="btn-save-profile-photo">
              <Buttom size="lg" label="Guardar" onClick={savePhotoProfile} />
              <div
                onClick={() => [
                  setPhotoProfile(user.image),
                  setIsChangeProfile(false),
                ]}
                className="option-logout"
              >
                Cancelar
              </div>
            </div>
          )}
        </div>

        {section === 'profile' && (
          <>
            <div
              className="option-profile"
              onClick={() => setSection('personal_data')}
            >
              Editar información personal
            </div>

            <div
              className="option-profile"
              onClick={() => setSection('preferences')}
            >
              Lo que busco / Lo que ofrezco
              </div>
            <div
              className="option-profile"
              onClick={() => setSection('interest')}
            >
              Configuración de intereses
            </div>
            <div
              className="option-profile"
              onClick={() => setSection('password')}
            >
              Editar contraseña
            </div>

            <br />
            <br />
            <br />
            <div className="option-logout" onClick={logout}>
              Cerrar sesión
            </div>
          </>
        )}

        {section === 'preferences' && (
          <SettingsPreferences setSection={() => setSection('profile')} />
        )}

        {section === 'password' && (
          <SettingsPassword setSection={() => setSection('profile')} />
        )}

        {section === 'personal_data' && (
          <SettingsPersonalData setSection={() => setSection('profile')} />
        )}

        {section === 'interest' && (
          <SettingsInterest setSection={() => setSection('profile')} />
        )}
      </div>
    </Layout>
  );
};

export default LoginForm;
