import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Splash from '@components/splash';

import useScrollToTop from 'hooks/useScrollTopNav';
import { useInit } from 'hooks/useInit';
import { useSelector } from 'react-redux';
import { AppStateType } from '@redux/store';

import {
  HomeScreen,
  SingInScreen,
  SingUnScreen,
  LaunchScreen,
  DirectorieScreen,
  ProfileDirectorieScreen,
  MachingPeopleScreen,
  ProfileScreen,
  MatchesScreen,
  SpeedDatingInvitationsScreen,
  SpeedDatingSchedulleScreen,
  SpeedDatingSchedulleAppointmentsScreen,
  SpeedDatingScreen,
  Match,
  Photo,
} from '@screens/index';

const RootNavigator = () => {
  useInit();

  const { isFirstLoad, isSingin } = useSelector(
    (state: AppStateType) => state.initLoad,
  );

  return (
    <>
      {isFirstLoad ? (
        <Splash />
      ) : (
        <Router>
          <ScrollToTop />

          {!isSingin ? (
            <Routes>
              <Route path="/" element={<LaunchScreen />} />
              <Route path="/sing-in" element={<SingInScreen />} />
              <Route path="/sing-up" element={<SingUnScreen />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/" element={<HomeScreen />} />
              <Route path="/explore" element={<MachingPeopleScreen />} />
              <Route path="/profile" element={<ProfileScreen />} />
              <Route path="/directorie" element={<DirectorieScreen />} />
              <Route path="/matches" element={<MatchesScreen />} />
              <Route
                path="/directorie/profile"
                element={<ProfileDirectorieScreen />}
              />

              <Route path="/speed/dating" element={<SpeedDatingScreen />} />
              <Route
                path="/speed/dating/invitations"
                element={<SpeedDatingInvitationsScreen />}
              />
              <Route
                path="/speed/dating/shedulle"
                element={<SpeedDatingSchedulleScreen />}
              />
              <Route
                path="/speed/dating/shedulle/appointments"
                element={<SpeedDatingSchedulleAppointmentsScreen />}
              />
               <Route path="/match" element={<Match />} />
               <Route path="/photo" element={<Photo />} />

            </Routes>
          )}
        </Router>
      )}
    </>
  );
};

const ScrollToTop: React.FC = () => {
  useScrollToTop();
  return null;
};
export default RootNavigator;
