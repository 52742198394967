import DirectorieScreen from '@screens/directorie';

import './style.css';
import { useSelector } from 'react-redux';
import { AppStateType } from '@redux/store';
import {
  WhatLookingtScreen,
  WhatOffertScreen,
  InterestScreen,
  Photo,
} from '..';
function App() {
  const { user } = useSelector((state: AppStateType) => state.initLoad);
  return (
    <>
      {user.image === '' || user.image === null ? (
        <Photo />
      ) : user.what_offer === '' || user.what_offer === null ? (
        <WhatOffertScreen />
      ) : user.what_looking === '' || user.what_looking === null ? (
        <WhatLookingtScreen />
      ) : user.interest === '' || user.interest === null ? (
        <InterestScreen />
      ) : (
        <DirectorieScreen />
      )}
    </>
  );
}

export default App;
