import { isSingin, userData } from '@redux/slices/initLoad/types';
import { store } from '@redux/store';
import requests from 'helpers/requests';
import { AuthProps } from 'types/auth';

export const authLogin = async (data: AuthProps) => {
  try {
    const response = await requests.post('auth', data);

    localStorage.setItem('@tokenAuth', response.data.access_token);
    localStorage.setItem('@userData', JSON.stringify(response.data.user));
    store.dispatch(
      userData({
        id: response.data.id,
        name: response.data.user.name,
        image: response.data.user.image,
        email: response.data.user.email,
        instagram: response.data.user.instagram,
        phone: response.data.user.phone,
        document: response.data.user.document,
        location: response.data.user.location,
        company: response.data.user.company,
        interest: response.data.user.interest,
        rol: response.data.user.rol,
        what_offer: response.data.user.what_offer,
        what_looking: response.data.user.what_looking,
        type_company_work: response.data.user.type_company_work,
        pay: response.data.user.pay,
        pay_vip: response.data.user.pay_vip,
        email_verified_at: response.data.user.email_verified_at,
        created_at: response.data.user.created_at,
        updated_at: response.data.user.updated_at,
      }),
    );
    store.dispatch(isSingin(true));
    return response.data;
  } catch (error: any) {
    console.log({ error });
    const errorMessage =
      error?.response?.data?.message || 'An unexpected error occurred';
    throw new Error(errorMessage);
  }
};

export const signUp = async (data: any) => {
  try {
    const response = await requests.post('sing/up', data);

    localStorage.setItem('@tokenAuth', response.data.access_token);
    localStorage.setItem('@userData', JSON.stringify(response.data.user));
    store.dispatch(
      userData({
        id: response.data.id,
        name: response.data.user.name,
        image: response.data.user.image,
        email: response.data.user.email,
        instagram: response.data.user.instagram,
        phone: response.data.user.phone,
        document: response.data.user.document,
        location: response.data.user.location,
        company: response.data.user.company,
        interest: response.data.user.interest,
        rol: response.data.user.rol,
        what_offer: response.data.user.what_offer,
        what_looking: response.data.user.what_looking,
        type_company_work: response.data.user.type_company_work,
        pay: response.data.user.pay,
        pay_vip: response.data.user.pay_vip,
        email_verified_at: response.data.user.email_verified_at,
        created_at: response.data.user.created_at,
        updated_at: response.data.user.updated_at,
      }),
    );
    store.dispatch(isSingin(true));
    return response.data;
  } catch (error: any) {
    console.log({ error });
    const errorMessage =
      error?.response?.data?.message || 'An unexpected error occurred';
    throw new Error(errorMessage);
  }
};

export const saveWhatOffert = async (data: { text: string }) => {
  try {
    const response = await requests.post('save/what/offert', data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    const errorMessage =
      error?.response?.data?.message || 'An unexpected error occurred';
    throw new Error(errorMessage);
  }
};

export const saveWhatLooking = async (data: { text: string }) => {
  try {
    const response = await requests.post('save/what/looking', data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    const errorMessage =
      error?.response?.data?.message || 'An unexpected error occurred';
    throw new Error(errorMessage);
  }
};

export const saveIntereset = async (interest: string) => {
  try {
    const response = await requests.post('save/intereset', { interest });
    return response.data;
  } catch (error: any) {
    console.log({ error });
    const errorMessage =
      error?.response?.data?.message || 'An unexpected error occurred';
    throw new Error(errorMessage);
  }
};
