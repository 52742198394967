import './style.css';
import Layout from '@components/layout';
import { useMatches } from '@hooks/useMatches';
import { Link } from 'react-router-dom';

const LoginForm = () => {
  const { data } = useMatches();

  return (
    <Layout showBottomNav={true}>
      <div id="matches">
        <h2>Chats</h2>
  
        {data.length > 0 ? (
          data.map((item: any) => {
            return (
              <div className="math-item" key={item.phone}>
                <div>
                  <span>{item.name}</span>
                </div>
  
                <div>
  <a
    href={`https://wa.me/${item.phone.startsWith('3') ? '57' + item.phone : item.phone}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    <i className="fab fa-whatsapp whatsapp"></i>
  </a>
  &nbsp; &nbsp;
  <Link to="/directorie/profile" state={{ profile: item }}>
    <i className="fas fa-user"></i>
  </Link>
</div>

              </div>
            );
          })
        ) : (
          <p>No hay resultados disponibles</p>
        )}
      </div>
    </Layout>
  );
  
};

export default LoginForm;
