import './style.css';
import Layout from '@components/layout';
import Modal from '@mui/material/Modal';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useSlots } from '@hooks/useSlots';
import { useDirectorie } from '@hooks/useDirectorie';
import { UserData } from '@redux/slices/initLoad/types';
import { Link } from 'react-router-dom';
import { InputText } from '@components/forms';
import { useSelector } from 'react-redux';
import { AppStateType } from '@redux/store';

const Index = () => {
  const { data, invitations, dates, reserve } = useSlots();
  const { user } = useSelector((state: AppStateType) => state.initLoad);
  const { data: guest } = useDirectorie();
  const [openSlot, setOpenSlot] = useState(false);
  const [openGuest, setOpenGuest] = useState(false);
  const [search, setSearch] = useState('');
  const [dataGuset, setDataGuest] = useState<any>([]);

  const [selectedSlot, setSelectedSlot] = useState<any>(false);
  const [selectedGuest, setSelectedGuest] = useState<any>(false);

  const handleOpenSlot = () => setOpenSlot(true);
  const handleCloseSlot = () => setOpenSlot(false);

  const handleOpenGuest = () => setOpenGuest(true);
  const handleCloseGuest = () => setOpenGuest(false);

  interface UserDataWithMatch extends UserData {
    isMatched?: boolean;
  }
  const normalizeText = (text: any) => {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, ''); // Remove diacritics
  };

  useEffect(() => {
    setDataGuest(guest);
  }, [guest]);

  useEffect(() => {
    if (search.length < 3) {
      setDataGuest(guest);
    } else {
      const normalizedSearch = normalizeText(search.toLowerCase());
      const filteredGuests = guest.filter((g) =>
        normalizeText(g.name.toLowerCase()).includes(normalizedSearch),
      );
      setDataGuest(filteredGuests);
    }
  }, [search, guest]);

  const CardEntrepreneur = ({ data }: { data: UserDataWithMatch }) => {
    const defaultImage =
      'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

    return (
      <div
        className="content-card-entrepreneur"
        onClick={() => {
          setSelectedGuest(data);
          setOpenGuest(false);
        }}
      >
        <img
          src={data.image ?? defaultImage}
          onError={(e) => {
            (e.target as HTMLImageElement).src = defaultImage;
          }}
          alt={`${data.name}'s profile`}
        />
        <div className="entrepreneur-caption">
          <p className="title">{data.name}</p>
          <p className="rol">{data.rol}</p>
          {data.isMatched && (
            <span className="badge">Posible Networking</span>
          )}{' '}
          {/* Badge para coincidencias */}
        </div>
      </div>
    );
  };

  const handleReserve = () => {
    const data = {
      slot_id: selectedSlot.id,
      guest_id: selectedGuest.id,
    };

    if (selectedGuest && selectedSlot) {
      reserve(data);
    } else {
      alert('Todos los campos son requeridos');
    }
  };
  return (
    <Layout showBottomNav={true}>
      <div id="dating-invitation">
        <h2>Agendamiento de citas</h2>

        <div className="speed-dating-screen">
          <h1>Hola, {user.name}</h1>
          <p>
            ¡Bienvenid@ al SpeedDating del Cervezatón! En este espacio podrás
            agendar reuniones para conectar y hacer networking con otras
            personas, mientras disfrutas de una buena pola.
          </p>
          <strong>
            ¡Aprovecha esta oportunidad para conocer, compartir y pasar un rato
            genial!
          </strong>

          <div className="info-container">
            <div className="info-box">
              <div className="circle-number">{3 - dates.length}</div>
              <p>
                <strong>Disponibles</strong>
              </p>
              <p>Esta es la cantidad de SpeedDating que puedes agendar</p>
            </div>
            <div
              className="info-box2"
              style={{ display: 'flex', textAlign: 'left' }}
            >
              <div style={{ padding: 5 }}>
                <p>
                  <strong>Invitaciones</strong>
                </p>
                <p>
                  Visualiza que personas te están invitando a un SpeedDating
                </p>
                <br />

                <Link
                  state={{ invitations }}
                  to="/speed/dating/invitations"
                  className="invitation-button"
                >
                  Ver invitaciones
                </Link>
              </div>
              <div className="circle-number green">{invitations.length}</div>
            </div>
          </div>

          <h2>Agenda tu espacio ahora</h2>

          <Modal
            open={openGuest}
            onClose={handleCloseGuest}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-guest"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
              }}
            >
              {/* Botón de cerrar */}

              <div className="content-guest">
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <button
                    onClick={handleCloseGuest}
                    style={{
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                      fontSize: '1.5rem',
                      marginTop: 40,
                      marginRight: 0,
                    }}
                    aria-label="close"
                  >
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </button>
                </div>
                <InputText
                  value={search}
                  onChange={setSearch}
                  placeholder="Buscar personas"
                />
                <br />
                <br />

                <div className="availability-screen">
                  {dataGuset.map((item: any, _: any) => (
                    <CardEntrepreneur key={item.id} data={item} />
                  ))}
                </div>
              </div>
            </Box>
          </Modal>

          <Modal
            open={openSlot}
            onClose={handleCloseSlot}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
              }}
            >
              {/* Botón de cerrar */}
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button
                  onClick={handleCloseSlot}
                  style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '1.5rem',
                    marginTop: 40,
                    marginRight: 0,
                  }}
                  aria-label="close"
                >
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>

              <div className="availability-screen content-slots">
                {data.map((slot: any, index: any) => (
                  <div
                    key={index}
                    className="time-slot"
                    onClick={() => {
                      setSelectedSlot(slot);
                      handleCloseSlot();
                    }}
                  >
                    <h3>
                      {slot.start_time} a {slot.end_time}
                    </h3>
                    <p>{slot.available_slots} cupos disponibles</p>
                  </div>
                ))}
              </div>
            </Box>
          </Modal>

          <div className="select-box" onClick={handleOpenSlot}>
            <span>
              {selectedSlot
                ? `${selectedSlot.start_time} a ${selectedSlot.end_time}`
                : 'Elige un horario'}
            </span>
          </div>

          <div className="select-box" onClick={handleOpenGuest}>
            <span>
              {selectedGuest ? `${selectedGuest.name}` : 'Elige tu invitado'}
            </span>
          </div>

          <button className="schedule-button" onClick={handleReserve}>
            Agendar SpeedDating
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default Index;
