import './style.css';
import Buttom from '@components/buttons';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppStateType, store } from '@redux/store';
import { interest } from '@screens/home/interest';
import { saveIntereset } from '@hooks/useAuth';
import { useToast } from '@context/ToastContext';
import { userData } from '@redux/slices/initLoad/types';

const Index = ({ setSection }: { setSection: any }) => {
  const { showToast } = useToast();
  const { user } = useSelector((state: AppStateType) => state.initLoad);

  const [selectedInterests, setSelectedInterests] = useState<string[]>(
    user.interest?.split(',') ?? [],
  );

  const handleSelectInterest = (interest: string) => {
    if (selectedInterests.includes(interest)) {
      setSelectedInterests(selectedInterests.filter((i) => i !== interest));
    } else {
      setSelectedInterests([...selectedInterests, interest]);
    }
  };

  const saveData = async () => {
    try {
      await saveIntereset(selectedInterests.join(','))
        .then(() => {
          showToast('Success.', 'success');
          store.dispatch(
            userData({ ...user, interest: selectedInterests.join(',') }),
          );
        })
        .catch(() => {
          showToast('Ha ocurrido un error.', 'error');
        })
        .finally(() => {});
      setSection();
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div id="settings-password">
      <h2 style={{ textAlign: 'left' }}>Intereses</h2>
      <div className="badges-container">
        {interest.map((item, index) => (
          <span
            key={index}
            className={`badge-item ${
              selectedInterests.includes(item) ? 'selected' : ''
            }`}
            onClick={() => handleSelectInterest(item)}
          >
            {item}
          </span>
        ))}
      </div>

      <br />

      <div>
        <Buttom size="lg" label="Guardar" onClick={saveData} />
        <div onClick={setSection} className="option-logout">
          Cancelar
        </div>
      </div>
    </div>
  );
};

export default Index;
