// src/components/ButtonWithRipple.tsx

import React from 'react';
import './style.css';

type Props = {
  label?: string;
  placeholder?: string;
  size?: 'xs' | 'md' | 'lg';
  icon?: string;
  action?: string;
  disabled?: boolean;
  value: string;
  onChange?: (value: string) => void;
};

const Index: React.FC<Props> = ({
  placeholder = '',
  size = 'xs',
  label = '',
  action = '',
  disabled = false,
  value,
  onChange,
}) => {
  const inputClass = `input-${size}`;

  return (
    <>
      {label !== '' && <label className="label-input">{label}</label>}
      <div className={`content-input ${disabled && 'disabled'}`}>
        <textarea
          className={inputClass}
          placeholder={placeholder}
          disabled={disabled}
          maxLength={100}
          value={value}
          onChange={(event) => {
            if (onChange) onChange(event.target.value);
          }}
        />
        {action !== '' && <span className="span-action">{action}</span>}
      </div>
    </>
  );
};

export default Index;
