import './style.css';
import Layout from '@components/layout';

const LoginForm = () => {
  const timeSlots = [
    { start: '1:00pm', end: '1:30pm', available: 10 },
    { start: '1:45pm', end: '2:15pm', available: 10 },
    { start: '1:00pm', end: '1:30pm', available: 10 },
    { start: '1:45pm', end: '2:15pm', available: 10 },
    { start: '1:00pm', end: '1:30pm', available: 10 },
    { start: '1:45pm', end: '2:15pm', available: 10 },
  ];
  return (
    <Layout showBottomNav={true}>
      <div id="speed-dating-schedulle">
        <h2>SpeedDating</h2>
        <br />
        <div className="content-schedulle">
          <h3>Elige un horario</h3>
        </div>

        <div className="availability-screen">
          {timeSlots.map((slot, index) => (
            <div key={index} className="time-slot">
              <h3>
                Hoy: {slot.start} a {slot.end}
              </h3>
              <p>{slot.available} disponibles</p>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default LoginForm;
